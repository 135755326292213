import rectangle1 from "../../image/rectangle1.png";
import rectangle2 from "../../image/rectangle2.png";
import rectangle3 from "../../image/rectangle3.png";

function Advantages() {
  return (
    <div className="advantages" id='adv'>
      <div className="container">
        <div className="nameBlock">
          <h1>Переваги</h1>
        </div>
        <div className="itemsBlock">
          <div className="item">
            <img src={rectangle1} alt="" />
            <h3>СКАНУЙТЕ ТА КУПУЙТЕ В ПАРУ КЛІКІВ</h3>
            <p>
              За допомогою передових алгоритмів штучного інтелекту наша програма
              миттєво сканує та розпізнає рослини, а потім ви легко знайдете
              місця, де їх можна купити поблизу.
            </p>
          </div>
          <div className="item">
            <img src={rectangle2} alt="" />
            <h3>ДІЗНАЙТЕСЯ ХТО ПОРЯД РОСТЕ</h3>
            <p>
              Наш додаток зможе запропонувати вам інформацію про інші рослини,
              які процвітають у місцевості, де ви тут і зараз. Досить
              сфотографувати або встановити локацію. Ви дізнаєтеся їх назви,
              особливості та зможете познайомитися з найрізноманітнішими
              представниками флори.
            </p>
          </div>
          <div className="item" id="item3">
            <img src={rectangle3} alt="" />
            <h3>ДОТОРКНІТЬСЯ ДО МАГІЇ ПРИРОДИ</h3>
            <p>
              Із зібраних рослин AI може запропонувати вам варіанти рецептів для
              різних випадків і потреб. Вам не доведеться витрачати час на пошук
              потрібної інформації, адже AI підкаже найпопулярніші рецепти з
              Інтернету. А також ви дізнаєтеся всю символіку рослини, її корисні
              енергетичні властивості.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;
