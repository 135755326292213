import Iphone from "../../image/phone.png";

function Discription({ bgImage, discName, discText, id }) {
  return (
    <div className="discriptionAdvantages" data-bg={id}>
      <div className="container">
        {id === 1 ? <h1 className="nameBlock">Опис переваг</h1> : null}
        <div className="contentBlock content" data-content={id}>
          <div className="text" data-postext={id}>
            <h2>{discName}</h2>
            {discText}
          </div>
          <img
            src={Iphone}
            alt="phone"
            className="iphone"
            data-phone={id}
            style={{ zIndex: 1 }}
          />
        </div>
        <img src={bgImage} alt="bgImg" className="bgImage" data-pos={id} />
      </div>
    </div>
  );
}

export default Discription;
