import baner1 from "../../image/baner1.svg";
import baner2 from "../../image/baner2.svg";
import baner3 from "../../image/baner3.svg";
import baner4 from "../../image/baner4.svg";
import baner5 from "../../image/baner5.svg";

function InfoBaner() {
  return (
    <div className="infoBaner" id='info'>
      <div className="container">
        <div className="baner">
          <div className="banerItem">
            <img src={baner1} alt="" />
            <h5>Швидка обробка фото</h5>
          </div>
          <div className="banerItem">
            <img src={baner2} alt="" />
            <h5>Хто росте поруч</h5>
          </div>
          <div className="banerItem">
            <img src={baner3} alt="" />
            <h5>Штучний інтелект(AI)</h5>
          </div>
          <div className="banerItem" id="baner4">
            <img src={baner4} alt="" />
            <h5>Знайшов - придбав</h5>
          </div>
          <div className="banerItem" id="baner5">
            <img src={baner5} alt="" />
            <h5>Інтуїтивно зрозумілий користувацький інтерфейс</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoBaner;
