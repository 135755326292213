
function Footer() {
  return (
    <div className="footer">
      <div className="footerName">
        <h3>©2023 Magic Plant App</h3>
      </div>
      <div className="footerInfo">
        <h3>
          <a href="##">Контакти</a>
        </h3>
        <h3>Політика конфіденційності</h3>
      </div>
    </div>
  );
}

export default Footer;
