import HeaderBlock from "./components/header/HeaderBlock";
import Advantages from "./components/advantages/Advantages";
import InfoBaner from "./components/infoBaner/InfoBaner";
import Discription from "./components/discriptionAdvantages/Discription";
import Custdev from "./components/custdev/Custdev";
import Footer from "./components/footer/Footer";
import disc1 from "./image/disc1.svg";
import disc2 from "./image/disc2.svg";
import disc3 from "./image/disc3.svg";

export default function App() {
  const discSRC = [
    {
      id: 1,
      bgImage: disc1,
      discName: "СКАНУЙТЕ ТА КУПУЙТЕ",
      discText: (
        <p>
          Ця функція дозволяє{" "}
          <b>
            <u style={{ fontSize: 20 + "px" }}>заощадити час на пошук</u>
          </b>{" "}
          потрібної рослини, надаючи всі варіанти та сортуючи за потреби. Також
          цей додаток надасть можливість взаємодіяти як з маркетплейсами, так і
          з сайтами для розплідників та іншими, вибираючи для вас оптимальні
          варіанти на основі ваших уподобань, які AI буде вивчати під час
          взаємодії.
        </p>
      ),
    },
    {
      id: 2,
      bgImage: disc2,
      discName: "ДІЗНАЙТЕСЬ ХТО РОСТЕ ПОРУЧ",
      discText: (
        <p>
          Це чудова можливість досліджувати природу, відпочити з користю,
          збирати гриби, лікарські рослини. Ви також можете{" "}
          <b style={{ fontSize: 20 + "px" }}>залишити геотег</b>, який допоможе
          іншим прихильникам природи знайти те, що  їм потрібно. <br />А ще є
          розділ при пошуку рослин за місцевістю –{" "}
          <b>
            <u style={{ fontSize: 20 + "px" }}>Червона книга</u>
          </b>
          . <br />
          Тут можна дізнатися, які рослини знаходяться на межі зникнення, а які
          не можна збирати. <br />А в розділі купити - можна{" "}
          <b>
            <u style={{ fontSize: 20 + "px" }}>зробити добру справу</u>
          </b>
          , придбавши рідкісну рослину в розпліднику і посадивши її в місці, де
          вона приживеться.
        </p>
      ),
    },
    {
      id: 3,
      bgImage: disc3,
      discName: "МАГІЯ ПРИРОДИ",
      discText: (
        <p>
          Ні для кого не секрет, що рослини і гриби мають магічні властивості.
          Вони мають своє значення в геральдиці, лікуванні та магії. Адже людина
          – це частина природи, звідки вона до неї прийшла кам'яних джунглів,
          наповнений і натхненний її справжньою красою та силою.У додатку ви
          можете дізнатися цінну інформацію про те,{" "}
          <b>
            <u style={{ fontSize: 20 + "px" }}>
              як рослини можуть допомогти вам енергетично
            </u>
          </b>
          . Дендротерапія, лісова біоенергетика. Все це вам розповість штучний
          інтелект (AI).
        </p>
      ),
    },
  ];

  return (
    <>
      <HeaderBlock />
      <Advantages />
      <InfoBaner />
      {discSRC.map((el) => (
        <Discription key={el.id} {...el} />
      ))}
      <Custdev />
      <Footer />
    </>
  );
}
