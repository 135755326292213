import React from "react";
import { useState } from "react";
import Logo from "../../image/LogoMagicPlant.svg";

export default function HeaderMenu() {
  const [burgerActive, setBurgerActive] = useState(false);

  const burgerHandler = () => {
    setBurgerActive(!burgerActive);
  };

  return (
    <div className="headerMenu">
      <label>
        <img src={Logo} alt="logo" className="ActiveLogo" />
        <input
          type="checkbox"
          className={burgerActive ? "active" : ""}
          onClick={burgerHandler}
        />
        <span className="menu">
          <span className="hamburger"></span>
        </span>
        <ul>
          <li>
            <a href="##">
              <img src={Logo} alt="logo" className="logo" />
            </a>
          </li>
          <li>
            <a href="/">Огляд</a>
          </li>
          <li>
            <a href="#adv" onClick={burgerHandler}>Переваги</a>
          </li>
          <li>
            <a href="#info" onClick={burgerHandler}>Опис переваг</a>
          </li>
          <li>
            <a target="blank" href='https://docs.google.com/forms/d/e/1FAIpQLSeeTLUBqTP0DTtu8qnvfgXQltLTogklZx9zUdyMrW84KE2luw/viewform?usp=sf_link'>Опитування</a>
          </li>
          <li>
            <button className="btn6">Контакти</button>
          </li>
        </ul>
      </label>
    </div>
  );
}
