import HeaderMenu from "./HeaderMenu";
import Iphone from "../../image/IphoneHeader.png";
import lightColumn from "../../image/ContentLightColumn.svg";

export default function HeaderBlock() {
  return (
    <div className="headerBlock">
      <div className="yellowBG"></div>
      <div className="container">
        <HeaderMenu />
        <div className="headerContent">
          <div className="textInfo">
            <h1 className="upperCase">ЛІКАРСЬКІ РОСЛИНИ</h1>
            <h1 id="textGradient">на кінчиках ваших пальців</h1>
            <h3>Ласкаво просимо у світ розпізнавання рослин!</h3>
            <p>
              Ласкаво просимо у світ розпізнавання рослин! Наш інноваційний
              додаток допоможе вам легко та точно ідентифікувати будь-яку
              рослину, яку ви зустрінете.
              <br /> <br />
              Програма в розробці, тому ми запрошуємо вас приєднатися та
              допомогти зробити додаток ще ефективнішим для Вас.
            </p>
            <button className="btnHeader">ПРИЄДНАТИСЬ ДО НАС</button>
          </div>
          <img src={Iphone} alt="phone" className="phoneSvg" />
          <img
            src={lightColumn}
            alt="content"
            className="ContentLightSvg"
          />
        </div>
      </div>
    </div>
  );
}
