import React from "react"

import Arrow from "../../image/ArrowHorizontal.svg"
import Earth from "../../image/EarthWithHands.png"


function Custdev() {
  return (
    <div className="custdev">
      <div className="fonYellow"></div>
      <div className="container">
        <div className="contentCustdev">
          <div className="textContent">
            <img src={Earth} alt="" className="earth" />
            <p style={{ paddingRight: 24}}>
              Відкрийте для себе дивовижний світ рослин із нашим інноваційним
              додатком! <br />
              <br />
              Ми пропонуємо не тільки фоторозпізнавання рослин, але й унікальний
              досвід покупки рослин онлайн.
            </p>
            <img src={Arrow} alt="" className="arrow" />
          </div>
          <div className="form" style={{marginBottom: 80, marginTop: 0}}>
            <p style={{margin: '60px 60px -60px', fontSize: 20}}>
                Для нас важлива думка та побажання кожного, щоб знати, що ви
                хотіли б бачити в нашому додатку. <br />
                <br />
                Будьте частиною нашої рослинної спільноти:{" "}
                <b>
                  візьміть участь в опитуванні та отримайте ексклюзивний доступ до
                  нових функцій і оновлень програм!
                </b>
              </p>
            <h1>Приєднуйтеся до спільноти</h1>
            <a target='_blank' href='https://forms.gle/qLraKVpTRqmDQqh1A' className="btnHeader" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none'}} rel="noreferrer">ПРИЄДНАТИСЬ ДО НАС</a>
            <p><br /><br /><br /></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Custdev
